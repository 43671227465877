import PropTypes from 'prop-types'
import React from 'react'
import logoImg from '../images/logo.png'
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import car1 from '../images/car1.jpg'
import car2 from '../images/car2.jpg'
import car3 from '../images/car3.jpg'
import car4 from '../images/car4.jpg'
import car5 from '../images/car5.jpg'
import car6 from '../images/car6.jpg'
import car7 from '../images/car7.jpg'
import car8 from '../images/car8.jpg'
import car9 from '../images/car9.jpg'
import car10 from '../images/car10.jpg'
import car11 from '../images/car11.jpg'
import car12 from '../images/car12.jpg'





const Header = props => (
    <header id="header" style={props.timeout ? { display: 'none' } : {}}>
        <div className="logo">
            <img src={logoImg} style={{width: "100%"}} />
        </div>
        <div className="content">
            <div className="inner">
                <h1 style={{marginBottom: "2rem"}}>Penser l'agencement, autrement...</h1>
                {/* <p>
                    A fully responsive site template designed by{' '}
                    <a href="https://html5up.net">HTML5 UP</a> and released
                        <br />
                        for free under the{' '}
                    <a href="https://html5up.net/license">Creative Commons</a> license.
                </p> */}
                <Carousel showThumbs={false} style={{maxWidth: "500px"}} showIndicators={false} showStatus={false} infiniteLoop={true} autoPlay>
                    <div>
                        <img src={car1} />
                    </div>
                    <div>
                        <img src={car2} />
                    </div>
                    <div>
                        <img src={car3} />
                    </div>
                    <div>
                        <img src={car4} />
                    </div>
                    <div>
                        <img src={car5} />
                    </div>
                    <div>
                        <img src={car6} />
                    </div>
                    <div>
                        <img src={car7} />
                    </div>
                    <div>
                        <img src={car8} />
                    </div>
                    <div>
                        <img src={car9} />
                    </div>
                    <div>
                        <img src={car10} />
                    </div>
                    <div>
                        <img src={car11} />
                    </div>
                    <div>
                        <img src={car12} />
                    </div>
                </Carousel>

            </div>
        </div>
        <nav>
            <ul>
                <li>
                    <button
                        onClick={() => {
                            props.onOpenArticle('intro')
                        }}
                    >
                        Influences
                    </button>
                </li>
                <li className="hoverable" style={{position: "relative"}}>
                    <button>
                        Savoirs
                    </button>
                    <ul className="submenu">
                        <li onClick={() => { props.onOpenArticle('work1') }}>Les solides surfaces</li>
                        <li onClick={() => { props.onOpenArticle('work2') }}>Les bois & dérivés</li>
                        <li onClick={() => { props.onOpenArticle('work3') }}>Les pierres naturelles</li>
                        <li onClick={() => { props.onOpenArticle('work4') }}>Les verres</li>
                        <li onClick={() => { props.onOpenArticle('work5') }}>Nos unités de production</li>
                        <li onClick={() => { props.onOpenArticle('work6') }}>Notre logistique</li>
                    </ul>
                </li>
                <li className="hoverable" style={{position: "relative"}}>
                    <button>
                        Domaines
                    </button>
                    <ul className="submenu">
                        <li onClick={() => { props.onOpenArticle('domaines1') }}>CHR</li>
                        <li onClick={() => { props.onOpenArticle('domaines2') }}>Tertiaire</li>
                        <li onClick={() => { props.onOpenArticle('domaines3') }}>Domaine médical</li>
                        <li onClick={() => { props.onOpenArticle('domaines4') }}>Boutiques & magasins</li>
                        <li onClick={() => { props.onOpenArticle('domaines5') }}>Résidentiel</li>
                        <li onClick={() => { props.onOpenArticle('domaines6') }}>Architectes</li>
                    </ul>
                </li>
                <li>
                    
                    <button
                        onClick={() => {
                            window.open('mailto:contact@riviera-agencement.com?subject=subject&body=body');
                        }}
                    >
                        Contact
                    </button>
                </li>
            </ul>
        </nav>
    </header>
)

Header.propTypes = {
    onOpenArticle: PropTypes.func,
    timeout: PropTypes.bool,
}

export default Header
