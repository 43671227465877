import React from 'react'
import PropTypes from 'prop-types'

const Footer = (props) => (
    <footer id="footer" style={props.timeout ? {display: 'none'} : {}}>
        <ul className="copyright" style={{listStyle: "none"}}>
            <li>Adresse: 92, chemin du Val FleuriLe Syrina Bat. A-20706 800 Cagnes-sur-Mer - France</li>
            <li>Adresse: 32 Allée de la Robertsau, 67000 Strasbourg - France</li>
            <li><a href="tel:33980877205">Telephone: + 33; 9 80 87 72 05</a></li>
            <li><a href="tel:33660469713">Mobile: + 33; 6 60 46 97 13</a></li>
            <li><a href="mailto: contact@riviera-agencement.com">Email:  contact@riviera-agencement.com</a></li>
        </ul>
    </footer>
)

Footer.propTypes = {
    timeout: PropTypes.bool
}

export default Footer
